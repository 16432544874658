import { Box } from "@components/Layout"
import { Overlay } from "@components/Overlay"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import React, { createRef, useEffect, useState } from "react"
import BurgerButton from "./BurgerButton"
import CartButton from "./CartButton"
import * as S from "./elements"
import { NavigationDesktop, NavigationMobile } from "./Navigation"
import { Logo } from "@components/Icons"
import { rem } from "polished"
import IntlLink from "@components/Intl/IntlLink"
import SearchButton from "./SearchButton"

export type HeaderMode = "sticky" | "transparant" | "slide-in"

interface Props {
  mode?: HeaderMode
}

const Header: React.FC<Props> = ({ mode = "sticky" }) => {
  const [navActive, setNavActive] = useState(false)
  const [visible, setVisible] = useState(false)
  const ref = createRef<HTMLDivElement>()

  useEffect(() => {
    // Toggle body scroll lock for mobile
    if (ref && ref.current) {
      if (navActive) {
        disableBodyScroll(ref.current)
      }
    } else {
      clearAllBodyScrollLocks()
    }
  }, [navActive, ref])

  useEffect(() => {
    // This effect is only needed if we need to slide the header in
    if (mode !== "slide-in") {
      return
    }
    let height = window.innerHeight
    let mounted = true

    const handleScroll = () => {
      if (window.pageYOffset > height && !visible && mounted) {
        setVisible(true)
      } else if (window.pageYOffset < height && visible && mounted) {
        setVisible(false)
      }
    }

    const handleResize = () => {
      height = window.innerHeight
    }

    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", handleResize)

    return () => {
      mounted = false
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleResize)
    }
  })
  const hideNav = () => setNavActive(false)
  const toggleNav = () => setNavActive(!navActive)
  return (
    <>
      <S.Header
        mode={navActive ? "sticky" : mode}
        visible={mode === "slide-in" ? visible : true}
      >
        <S.Container justifyContent="space-between">
          <Box display={["block", "block", "none"]}>
            <BurgerButton
              active={navActive}
              onClick={toggleNav}
              data-testid="toggle-mobile-nav"
            />
          </Box>
          <Box display="flex" maxWidth={rem(130)} alignItems="center" mt={2}>
            <IntlLink href="/" as="/">
              <a href="/" title="Homepage">
                <Logo />
              </a>
            </IntlLink>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display={["none", "none", "block"]}>
              <NavigationDesktop mode={mode} />
            </Box>
            <SearchButton />
            <CartButton />
          </Box>
        </S.Container>

        {navActive && (
          <S.MobileNavContainer ref={ref} data-testid="mobile-nav">
            <NavigationMobile hideNav={hideNav} mode={mode} />
          </S.MobileNavContainer>
        )}
      </S.Header>
      {navActive && <Overlay onTouchMove={hideNav} onClick={hideNav} />}
    </>
  )
}

export default Header
