import React from "react"
import * as S from "./elements"
import { Search } from "@components/Icons"
import IntlLink from "@components/Intl/IntlLink"
import { useIntl } from "@components/Intl"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const SearchButton: React.FC<Props> = () => {
  const { messages } = useIntl()
  return (
    <IntlLink
      href={messages.navigation.search.url}
      as={messages.navigation.search.url}
    >
      <S.CardButton href={messages.navigation.search.url}>
        <Search />
      </S.CardButton>
    </IntlLink>
  )
}

export default SearchButton
