import styled, { css } from "styled-components"

export const Button = styled.button`
  margin: 0;
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
  background: none;
  position: relative;
  border: 0;
  color: inherit;
  outline: 0;
  span {
    background: currentColor;
  }
`

export const Burger = styled.div<{ active: boolean }>`
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  span {
    transition: width 0.2s;
    position: absolute;
    width: ${(p) => (!p.active ? "100%" : 0)};
    height: 2px;
    left: 0;

    &:nth-child(1) {
      top: 2px;
    }
    &:nth-child(2) {
      top: calc(50% - 1px);
    }
    &:nth-child(3) {
      bottom: 2px;
    }

    ${(p) =>
      p.active
        ? css`
            &:nth-child(1) {
              transition-delay: 0.1;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
            &:nth-child(3) {
              transition-delay: 0.3s;
            }
          `
        : css`
            &:nth-child(1) {
              transition-delay: 0.3s;
            }
            &:nth-child(2) {
              transition-delay: 0.4s;
            }
            &:nth-child(3) {
              transition-delay: 0.5s;
            }
          `}
  }
`

export const Cross = styled.div<{ active: boolean }>`
  transform: rotate(45deg);
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  span {
    position: absolute;
    &:nth-child(1) {
      transition: width 0.2s;
      width: ${(p) => (p.active ? "100%" : 0)};
      height: 2px;
      top: calc(50% - 1px);
      left: 0;
    }
    &:nth-child(2) {
      transition: height 0.2s;
      width: 2px;
      height: ${(p) => (p.active ? "100%" : 0)};
      left: calc(50% - 1px);
      top: 0;
    }

    ${(p) =>
      p.active
        ? css`
            &:nth-child(1) {
              transition-delay: 0.4s;
            }
            &:nth-child(2) {
              transition-delay: 0.5s;
            }
          `
        : css`
            &:nth-child(1) {
              transition-delay: 0.1s;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
          `}
  }
`
