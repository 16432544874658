import { DropdownSmall } from "@components/Icons"
import { useIntl } from "@components/Intl"
import { Box } from "@components/Layout"
import React, { useState } from "react"
import * as S from "./elements"

interface Props {}

const ToggleLang: React.FC<Props> = () => {
  const ctx = useIntl()
  const { messages } = ctx
  const [active, setActive] = useState(false)

  return (
    <>
      <S.Toggle
        onClick={() => setActive(!active)}
        active={active}
        data-testid="locale-button"
      >
        <S.AnchorContent>
          <Box as="span" display={["initial", "none"]}>
            {messages.global.language}:
          </Box>
          {ctx.locale !== "en" ? " NL" : " EN"}
        </S.AnchorContent>
        <DropdownSmall />
      </S.Toggle>
      {active && (
        <>
          <S.CloseMenu onClick={() => setActive(false)} />
          <S.LangWrap isVisible={active}>
            <S.Toggle
              data-testid="locale-nl"
              onClick={() => {
                ctx.setLocale("nl")
                setActive(false)
              }}
              disabled={ctx.locale === "nl"}
            >
              <S.AnchorContent>{messages.global.dutch}</S.AnchorContent>
            </S.Toggle>
            <S.Toggle
              data-testid="locale-en"
              onClick={() => {
                ctx.setLocale("en")
                setActive(false)
              }}
              disabled={ctx.locale === "en"}
            >
              <S.AnchorContent>{messages.global.english}</S.AnchorContent>
            </S.Toggle>
          </S.LangWrap>
        </>
      )}
    </>
  )
}

export default ToggleLang
