import { useIntl } from "@components/Intl"
import React from "react"
import ActiveLink from "./ActiveLink"
import * as S from "./elements"
import ToggleLang from "./ToggleLang"
import { HeaderMode } from ".."

interface Props {
  hideNav?: () => void
  mode?: HeaderMode
}

export const NavigationMobile: React.FC<Props> = ({ hideNav, mode }) => {
  const { messages } = useIntl()

  return (
    <S.Nav mode={mode}>
      <ActiveLink as="/" href="/" data-testid="home-link" onClick={hideNav}>
        <S.AnchorContent>Home</S.AnchorContent>
      </ActiveLink>
      <ActiveLink
        as={messages.navigation.collection.url}
        href={messages.navigation.collection.url}
        onClick={hideNav}
      >
        <S.AnchorContent>
          {messages.navigation.collection.title}
        </S.AnchorContent>
      </ActiveLink>
      <ActiveLink as="/looks" href="/looks" onClick={hideNav}>
        <S.AnchorContent>Looks</S.AnchorContent>
      </ActiveLink>
      <ActiveLink as="/retailers" href="/retailers" onClick={hideNav}>
        <S.AnchorContent>Retailers</S.AnchorContent>
      </ActiveLink>
      <ActiveLink
        as="/contact"
        href="/contact"
        data-testid="contact-link"
        onClick={hideNav}
      >
        <S.AnchorContent>Contact</S.AnchorContent>
      </ActiveLink>
      <ToggleLang />
    </S.Nav>
  )
}
export const NavigationDesktop: React.FC<Props> = ({ mode }) => {
  const { messages } = useIntl()

  return (
    <S.Nav mode={mode}>
      <ActiveLink as="/" href="/">
        <S.AnchorContent>Home</S.AnchorContent>
      </ActiveLink>
      <ActiveLink
        as={messages.navigation.collection.url}
        href={messages.navigation.collection.url}
      >
        <S.AnchorContent>
          {messages.navigation.collection.title}
        </S.AnchorContent>
      </ActiveLink>
      <ActiveLink as="/looks" href="/looks">
        <S.AnchorContent>Looks</S.AnchorContent>
      </ActiveLink>
      <ActiveLink as="/retailers" href="/retailers">
        <S.AnchorContent>Retailers</S.AnchorContent>
      </ActiveLink>
      <ActiveLink as="/contact" href="/contact">
        <S.AnchorContent>Contact</S.AnchorContent>
      </ActiveLink>
      <ToggleLang />
    </S.Nav>
  )
}
