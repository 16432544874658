import { getStructuredCustomConfigurations } from "./helpers/get-structured-custom-configurations"
import { ChoicesCartCustomComponent, ChoicesCartItem } from "./helpers/types"
import { useCart } from "./use-cart"

type StructuredCartItems = {
  articles: ChoicesCartItem[]
  articleCombinations: ChoicesCartItem[]
  customCombinations: ChoicesCartCustomComponent[]
  totalItems: number
}

export const CUSTOM_COMBINATION = "CUSTOM_COMBINATION"

export function useStructuredCartItems(): StructuredCartItems {
  const { data } = useCart()

  if (!data || !data.items) {
    return {
      articles: [],
      articleCombinations: [],
      customCombinations: [],
      totalItems: 0,
    }
  }

  let articles: ChoicesCartItem[] = []
  let articleCombinations: ChoicesCartItem[] = []
  let customCombinations = getStructuredCustomConfigurations(data.items)

  for (const item of data.items) {
    // if an article has a message, it's a custom combination
    if (item.choicesArticle && !item.message) {
      articles.push(item)
    }
    if (item.choicesArticleCombination) {
      articleCombinations.push(item)
    }
  }

  const totalItems = [
    ...articles,
    ...articleCombinations,
    ...customCombinations,
  ].reduce((acc, item) => acc + item.quantity, 0)

  return {
    articles,
    articleCombinations,
    customCombinations,
    totalItems,
  }
}
