import { breakpoints } from "@config/theme"
import styled from "styled-components"

export const CardButton = styled.a`
  margin: 0;
  padding: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  @media screen and (min-width: ${breakpoints.small}) {
    margin-right: 2.5rem;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`
