import styled from "styled-components"

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: ${(p) => p.theme.zIndex.overlay};
  background: rgba(0, 0, 0, 0.5);
`
