import IntlLink from "@components/Intl/IntlLink"
import { LinkProps } from "next/link"
import { useRouter } from "next/router"
import React from "react"
import * as S from "./elements"
import { useIntl } from "@components/Intl"

interface Props extends LinkProps {
  subNavActive?: boolean
  onClick?: () => void
}

const ActiveLink: React.FC<Props> = ({
  children,
  href,
  as,
  onClick,
  ...rest
}) => {
  const router = useRouter()
  const { locale } = useIntl()
  const title = typeof children === "string" ? children : ""

  let isCurrentPage = false
  if (typeof window === "undefined") {
    // SSR
    if (router && locale === "en") {
      if (router.asPath === "/en") {
        isCurrentPage = as === "/"
      } else {
        isCurrentPage = router.asPath.replace("/en", "") === as
      }
    }
  } else {
    if (router && locale === "en") {
      if (router.asPath === "/en") {
        isCurrentPage = as === "/"
      } else {
        isCurrentPage = `/en${as}` === router.asPath
      }
    } else if (router && locale === "nl") {
      isCurrentPage = as === router.asPath
    }
  }

  return (
    <IntlLink href={href} as={as}>
      <S.Anchor
        title={title}
        active={isCurrentPage}
        href={typeof as === "string" ? as : undefined}
        onClick={onClick}
      >
        {children}
      </S.Anchor>
    </IntlLink>
  )
}

export default ActiveLink
