import { CustomCombinationComponent } from "@lib/hooks/use-add-custom-combination-to-cart"

type CustomCombinationItemsInput = {
  stoneId: string | null
  frameId: string | null
  baseId: string | null
  type: "ring" | "earring"
}

export const CUSTOM_CONFIGURATION_BASE_ID = "-custom-configuration-base"

export function getCustomCombinationItems({
  stoneId,
  frameId,
  baseId,
  type,
}: CustomCombinationItemsInput): CustomCombinationComponent[] {
  let components: CustomCombinationComponent[] = []
  const isEarring = type === "earring"
  // Get a new unique ID for the group tying the components together
  const groupId = Math.random().toString(36).substring(2, 15)

  if (!baseId && !stoneId) {
    return []
  }

  if (baseId) {
    components.push({
      id: baseId,
      quantity: 1,
      message: `${groupId}${CUSTOM_CONFIGURATION_BASE_ID}`,
    })
  }

  if (stoneId) {
    components.push({
      id: stoneId,
      quantity: 1,
      message: groupId,
    })

    if (isEarring) {
      components.push({
        id: stoneId,
        quantity: 1,
        message: groupId,
      })
    }
  }

  if (frameId) {
    components.push({
      id: frameId,
      quantity: 1,
      message: groupId,
    })

    if (isEarring) {
      components.push({
        id: frameId,
        quantity: 1,
        message: groupId,
      })
    }
  }

  return components
}
