import styled, { css, keyframes } from "styled-components"
import { Box } from "@components/Layout"
import { rem } from "polished"
import { HeaderMode } from "."
import { breakpoints } from "@config/theme"

export const animateMobileNav = keyframes`
0% {
  opacity: 0;
  transform: translateY(-0.25rem);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`

export const Header = styled.header<{ mode: HeaderMode; visible?: boolean }>`
  transition: background 0.2s;
  top: 0;
  width: 100%;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.header};
  position: relative;
  transform: translateY(${(p) => (!p.visible ? "-6rem" : 0)});
  background: linear-gradient(180deg, #00000082, transparent);
  ${(p) =>
    p.mode === "transparant"
      ? css`
          position: absolute;
          color: #fff;

          a {
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          }
          svg {
            fill: #fff;
          }
        `
      : p.mode === "slide-in"
      ? css`
          transition: transform 0.25s ease-in-out;
          position: fixed;
          box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.04);
          background: #fff;
          color: #000;
          svg {
            fill: ${(p) => p.theme.colors.purple};
          }
        `
      : css`
          position: fixed;
          box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.04);
          background: #fff;
          color: #000;
          svg {
            fill: ${(p) => p.theme.colors.purple};
          }
        `};
  svg {
    width: 100%;
    height: 2.5rem;
  }
`

export const Container = styled(Box)`
  width: 90%;
  max-width: ${rem(1600)};
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: 4rem;
  @media screen and (min-width: ${breakpoints.small}) {
    min-height: 5rem;
    width: 95%;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    min-height: 6rem;
  }
`

export const MobileNavContainer = styled(Box)`
  transition: opacity 0.2s ease-in-out;
  backface-visibility: hidden;
  background: #fff;
  animation: ${animateMobileNav} 0.15s linear forwards;
  position: relative;
  width: 100%;
  opacity: 1;
  max-height: 70vh;
  padding: 2rem 0;
`
