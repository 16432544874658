import { AddToBag } from "@components/Icons"
import { useIntl } from "@components/Intl"
import IntlLink from "@components/Intl/IntlLink"
import { useStructuredCartItems } from "@lib/hooks/use-structured-cart-items"
import React from "react"
import * as S from "./elements"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const CartButton: React.FC<Props> = () => {
  const { totalItems } = useStructuredCartItems()
  const { messages } = useIntl()
  return (
    <IntlLink
      href={messages.navigation.cart.url}
      as={messages.navigation.cart.url}
    >
      <S.CardButton href={messages.navigation.cart.url}>
        <AddToBag />
        {totalItems && totalItems > 0 ? (
          <S.AmountContainer data-testid="cart-quantity">
            {totalItems}
          </S.AmountContainer>
        ) : null}
      </S.CardButton>
    </IntlLink>
  )
}

export default CartButton
