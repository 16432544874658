import { breakpoints } from "@config/theme"
import { rem } from "polished"
import styled, { css } from "styled-components"
import { HeaderMode } from ".."

export const Nav = styled.nav<{ mode?: HeaderMode }>`
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${breakpoints.small}) {
    flex-direction: row;
  }
`

export const AnchorContent = styled.span`
  cursor: pointer;
  text-decoration: none;
  font-size: ${rem(14)};
  line-height: ${rem(17)};
  text-align: right;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  color: inherit;

  transition: font-weight 0.2s ease-in-out;
  position: relative;
  &::before {
    transition: width 0.2s ease-in-out;
    content: "";
    position: absolute;
    bottom: -0.4rem;
    height: 1.5px;
    width: 100%;
    background: currentColor;
    width: 0;
  }
`

const itemBase = css`
  display: block;
  text-decoration: none;
  margin-bottom: 2rem;
  color: #000;
  background: transparent;
  border: none;

  @media screen and (min-width: ${breakpoints.small}) {
    margin-right: 2rem;
    margin-bottom: 0;
    color: inherit;
  }

  &:hover,
  &:focus {
    border-color: currentColor;
    outline: none;
    @media screen and (min-width: ${breakpoints.small}) {
      ${AnchorContent}::before {
        width: 100%;
      }
    }
  }
`

export const Anchor = styled.a<{ active?: boolean }>`
  ${itemBase};

  ${AnchorContent} {
    ${(p) =>
      p.active &&
      css`
        @media screen and (max-width: ${breakpoints.small}) {
          color: ${p.theme.colors.purple};
        }
        &::before {
          width: 100%;
        }
      `};
  }
`

export const Toggle = styled.button<{ active?: boolean }>`
  position: relative;
  ${itemBase};

  ${AnchorContent} {
    ${(p) =>
      p.active &&
      css`
        @media screen and (max-width: ${breakpoints.small}) {
          color: ${p.theme.colors.purple};
        }
        &::before {
          width: 100%;
        }
      `};
  }

  svg {
    height: 0.5rem;
    width: 0.5rem;
    transform: ${(p) => (p.active ? `rotate(180deg)` : ``)};
    margin-left: 0.25rem;
    transition: transform 0.2s ease-in-out;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const LangWrap = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(p) => p.theme.colors.text.dark};
  align-items: center;
  border-top: 1px solid ${(p) => p.theme.colors.softGrey};

  ${Toggle} {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  @media screen and (min-width: ${breakpoints.small}) {
    position: absolute;
    right: 0;
    background: white;
    bottom: 0;
    transform: translateY(calc(100% + 1rem));
    padding-bottom: 1rem;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(calc(100% + 1rem));
    width: auto;
    padding: 0 1rem 1rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    border-top: 0;
    align-items: flex-start;
    margin-right: 2rem;
  }
`

export const CloseMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`
