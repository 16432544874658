import styled, { keyframes } from "styled-components"

const shakeTheBag = keyframes`
0% {
  transform: rotate(0);
}
25% {
  transform: rotate(-15deg);
}
50% {
  transform: rotate(0);
}
75% {
  transform: rotate(15deg);
}
100% {
  transform: rotate(0);
}
`

export const CardButton = styled.a`
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
  svg {
    transition: transform 0.2s ease-in-out;
  }
  &:hover {
    svg {
      animation: ${shakeTheBag} 0.75s linear 1;
    }
  }
`

export const AmountContainer = styled.div`
  position: absolute;
  top: -0.8rem;
  right: -0.5rem;
  transform: translate(20%, 20%);
  height: 1.1rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.purple};
  color: white;
  font-size: 11px;
  font-weight: bold;
  min-width: 1.1rem;
`
