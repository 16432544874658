import React from "react"
import * as S from "./elements"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean
}

const BurgerButton: React.FC<Props> = ({ active, onClick, ...rest }) => {
  return (
    <S.Button onClick={onClick} {...rest}>
      <S.Burger active={active}>
        <span />
        <span />
        <span />
      </S.Burger>
      <S.Cross active={active}>
        <span />
        <span />
      </S.Cross>
    </S.Button>
  )
}

export default BurgerButton
