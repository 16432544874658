import { CUSTOM_CONFIGURATION_BASE_ID } from "@screens/configurator-v2/Navigation/helpers/get-custom-combination-items"
import { CUSTOM_COMBINATION } from "../use-structured-cart-items"
import { ChoicesCartCustomComponent, ChoicesCartItem } from "./types"

export function getStructuredCustomConfigurations(items: ChoicesCartItem[]) {
  let customCombinations: ChoicesCartCustomComponent[] = []

  // Get unique custom combination ids
  const customCombinationIds = items.reduce((acc, item) => {
    if (
      item.message &&
      !item.message.includes(CUSTOM_CONFIGURATION_BASE_ID) &&
      !acc.includes(item.message)
    ) {
      acc.push(item.message)
    }
    return acc
  }, [])

  // Structure each custom combination almost just like a normal choicesArticleCombination, but:
  // - take the main info from the base component
  // - add the cartItemdId to each article to easily in- and decrement the quantity & remove all the items from the cart
  // - add customCombinationId as the description, which is a bit hacky but keeps the data structure simple
  // -- this field is used in screens/cart/Row/ConfigurationRow/index.tsx to generate the customizeUrl
  for (const customCombinationId of customCombinationIds) {
    const customCombinationItems = items.filter(
      (item) => item.message && item.message.includes(customCombinationId)
    )
    const baseComponent = customCombinationItems.find((item) =>
      item.message.includes(CUSTOM_CONFIGURATION_BASE_ID)
    )

    if (
      !baseComponent ||
      !customCombinationItems ||
      customCombinationItems.length === 0
    ) {
      continue
    }

    const price = customCombinationItems.reduce((acc, item) => {
      return acc + item.unitPrice
    }, 0)

    let item: ChoicesCartCustomComponent = {
      articleId: CUSTOM_COMBINATION,
      choicesArticle: null,
      choicesArticleCombination: {
        articles: customCombinationItems.map((item) => ({
          component: item.choicesArticle.component,
          id: item.choicesArticle.id,
          price: item.choicesArticle.price,
          quantity: item.quantity,
          cartItemdId: item.id,
        })),
        baseMaterial: null,
        collection: "",
        combi_no: 0,
        gemstone: "",
        hasFixedComponent: false,
        id: baseComponent.id,
        images: null,
        material: baseComponent.choicesArticle.material,
        name: baseComponent.choicesArticle.name,
        price,
        product_group: baseComponent.choicesArticle.slug.includes("earring")
          ? "earring"
          : "ring",
        seq_nr: "",
        size: baseComponent.size,
        slug: baseComponent.choicesArticle.slug,
        style: baseComponent.choicesArticle.style,
        substyle: baseComponent.choicesArticle.substyle,
      },
      description: customCombinationId,
      id: baseComponent.id,
      message: null,
      quantity: baseComponent.quantity,
      size: baseComponent.choicesArticle.size,
      unitPrice: price,
      total: price * baseComponent.quantity,
      tax: 0,
    } as ChoicesCartCustomComponent

    customCombinations.push(item)
  }

  return customCombinations
}
